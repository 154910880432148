export enum EBarcodeSource {
    Rider = "riders",
    Checkpoint = "checkpoints",
}

export interface TBarcode {
    uid?: string;
    time: Date|string;
    code: string;
    message: string;

    control?: string;
    owner?: string;

    // a meaningful control identifier
    name?: string;
}

export type TSavedBarcode = {
    source: EBarcodeSource;
    sourceUid: string;
    barcode: TBarcode;
    token: string;
};

export type TBarcodeQueue = {
    [key: string]: TSavedBarcode;
}

export class Barcode implements TBarcode {
    uid?: string;
    time: Date;
    code: string;
    message: string;

    control?: string;
    owner?: string;

    // a meaningful control identifier
    name?: string;

    constructor(code = '', message = 'new', time: Date|string|null = new Date()) {
        this.time = time === null ? null : new Date(time);
        this.code = code;
        this.message = message;
    }

    static fromDoc(doc: TBarcode) {
        const {time, code, message, ...rest} = doc;
        const barcode = new Barcode(code, message, time);
        Object.assign(barcode, rest);
        return barcode;
    }
}
