import {h} from 'preact';
import {Link} from 'preact-router';
import {useTranslation} from "react-i18next";

import LocaleSwitcher from "./locale-switcher";

import style from './style.scss';

const Header = () => {
    const {t} = useTranslation();

    return (
        <header className={style.header}>
            <nav>
                <div className={style.link}>
                    <Link href="/">
                        <i className="material-icons">home</i>{t("brevetList.title")}
                    </Link>
                </div>
            </nav>
            &nbsp;
            <LocaleSwitcher />
        </header>
    );
}

export default Header;
