import {Checkpoint, TCheckpoint} from "./checkpoint";
import {LngLat, TrackPoint} from "./track-point";

export const NONE_BREVET = 'none';

/*
Short brevet description
- uid - unique identifier
- name - brevet title
- length - distance in km
- startDate - when to start
- endDate - last time to finish
*/
export type TBrevetShort = {
  uid: string;
  name: string;
  length: number;
  startDate: Date|string;
  endDate?: Date|string;
}

export type TBrevetFull = TBrevetShort & {
  mapUrl?: string;
  startPoint?: LngLat;
  track?: TrackPoint[];
  checkpoints?: (TCheckpoint|Checkpoint)[];
  results?: any;
}

export class Brevet implements TBrevetFull {
  uid: string;
  name: string;
  length: number;
  startDate: Date;
  endDate?: Date;

  mapUrl?: string;
  startPoint?: LngLat;
  track?: TrackPoint[];
  checkpoints?: Checkpoint[];
  results?: any;

  constructor(startDate: Date, endDate: Date = null) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  /*
  Create an object of class Brevet using properties given.
  - doc - a document with brevet description
  */
  static fromDoc(doc: TBrevetFull) {
    const {startDate, endDate, checkpoints, ...rest} = doc;
    // inflate the dates
    const brevet = new Brevet(new Date(startDate), endDate ? new Date(endDate) : null);
    brevet.checkpoints = checkpoints?.map((cp: TCheckpoint) => Checkpoint.fromDoc(cp));
    Object.assign(brevet, rest);
    return brevet;
  }

  get hasStarted(): boolean {
    const now: Date = new Date();
    return (!this.startDate || this.startDate <= now);
  }

  get hasEnded(): boolean {
    const now: Date = new Date();
    return (!!this.endDate && this.endDate < now);
  }

  get isOnline(): boolean {
    return this.hasStarted && !this.hasEnded;
  }

  mapAvailable(): boolean {
    return (this.track instanceof Array && this.track.length > 0)
        || (this.checkpoints instanceof Array && this.checkpoints.length > 0)
  }
}
