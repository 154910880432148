import {h} from "preact"
import {useContext} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {State, Storage} from "../app-state";
import LinkButton from "../link-button";

import style from './style.scss';

const LoginSettingsButton = () => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage);

    return (
        <div className={style.logInContainer}>
            {auth.value !== null ? (
                <LinkButton size="small" variant="fab" className={style.logInButton}
                            to="/settings" title={t('login.settings')}>
                    <i className="material-icons">person</i>
                </LinkButton>
            ) : (
                <LinkButton size="small" variant="fab" className={style.logInButton}
                            to="/login" title={t('login.login')}>
                    <i className="material-icons">login</i>
                </LinkButton>
            )}
        </div>
    )
}

export default LoginSettingsButton;
