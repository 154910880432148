import {h} from 'preact';
import {Route, Router} from 'preact-router';

// Code-splitting is automated for `routes` directory
import BrevetInfoRoute from "../routes/brevet-info";
import CheckInRoute from "../routes/brevet-info/check-in";
import BrevetListRoute from "../routes/brevet-list";
import CheckpointInfoRoute from "../routes/checkpoint-info";
import LoginRoute from "../routes/login";
import EmailLoginRoute from "../routes/login/email";
import SettingsRoute from "../routes/settings";
import {repeatSending} from "../services/barcode-queue";

import Header from './header';

const App = () => (
	<div id="app">
		<Header />
		<main>
			<Router>
				<Route path="/" component={BrevetListRoute} />
				<Route path="/brevets" component={BrevetListRoute} />
				<Route path="/brevet/:uid" component={BrevetInfoRoute} />
				<Route path="/brevet/:uid/checkin/:distance" component={CheckInRoute} />
				<Route path="/brevet/:brevetUid/checkpoint/:checkpointUid" component={CheckpointInfoRoute} />
				<Route path="/login" component={LoginRoute} />
                <Route path="/login/email" component={EmailLoginRoute} />
				<Route path="/settings" component={SettingsRoute} />
			</Router>
		</main>
	</div>
);

window.addEventListener('online', () => repeatSending());

export default App;
