import {computed, useSignal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Dropdown from "muicss/lib/react/dropdown";
import DropdownItem from "muicss/lib/react/dropdown-item";
import {Fragment, h} from "preact";
import {useContext, useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {Brevet} from "../../../models/brevet";
import {Checkpoint} from "../../../models/checkpoint";
import {EProgress, Progress} from "../../../models/progress";
import {LngLat} from "../../../models/track-point";
import {quickSearch} from "../../../utils/geo-location";
import {State, Storage} from "../../app-state";
import LeafletMap from "../../leaflet-map";
import LoginSettingsButton from "../../login-settings-button";

import style from "./style.scss";

type GeoCheckInProps = {
    brevet: Brevet;
    distance: number;
};

const GeoCheckIn = ({brevet, distance}: GeoCheckInProps) => {
    const {t} = useTranslation();

    const [selection, setSelection] = useState<string>("");
    const geoLocation = useSignal<LngLat>(null);
    const reducedCheckpoints = computed(() => quickSearch(geoLocation.value, brevet.checkpoints)
        .filter(cp => cp.distance >= distance)
        .sort((a: Checkpoint, b: Checkpoint) => (a.delta || 0) - (b.delta || 0)));

    const {progress}: State = useContext(Storage);
    const brevetProgress = computed(() => progress.value[brevet.uid] || new Progress(EProgress.Logged));

    const geoJson = !brevet.track ? null : {
        type: 'Feature',
        geometry: {
            type: 'LineString',
            coordinates: brevet.track.map(point => [
                point.coordinates?.lng,
                point.coordinates?.lat,
            ]),
        },
    };

    const updateProgress = (uid: string, payload: Progress) => {
        progress.value = { ...progress.value, [uid]: payload}
    }

    const sendControl = () => {
        const checkpoint: Checkpoint = brevet.checkpoints.find(cp => cp.uid === selection);
        if (checkpoint) {
            const [last] = brevet.checkpoints.slice(-1);

            if (checkpoint.distance === last?.distance) {
                updateProgress(brevet.uid, brevetProgress.value.change(EProgress.Finish));
            } else {
                updateProgress(brevet.uid, brevetProgress.value.change(EProgress.Control, checkpoint.distance, checkpoint.uid));
            }
        } else {
            console.error(`Selected checkpoint uid ${selection} not found`)
        }
        history.back();
    }

    const changeSelection = (event) => {
        setSelection(event.target.parentElement.getAttribute('uid'))
    }

    return (
        <>
            <h2 className={style.header}>
                <div className={style.title}>{t("brevetInfo.name", {name: brevet.name})}</div>
                &nbsp;
                <LoginSettingsButton />
            </h2>
            <h3>{t("brevetInfo.checkIn.title")}</h3>

            <LeafletMap
                checkpoints={brevet.checkpoints}
                geoJson={geoJson}
                startPoint={brevet.startPoint}
                geoLocation={geoLocation} />
            <div className={style.container}>

                {/* TODO: select and show the first checkpoint */}
                <Dropdown
                    className={style.selector}
                    variant="raised"
                    alignMenu="left"
                    disabled={!reducedCheckpoints.value.length}
                    label={t("checkIn.select")}>
                    {reducedCheckpoints.value
                        .map(cp => (
                            <DropdownItem key={cp.uid} onClick={changeSelection} uid={cp.uid}>{cp.name}</DropdownItem>
                        ))}
                </Dropdown>

                <Button onClick={() => history.back()}>{t("checkIn.cancel")}</Button>
                <Button onClick={sendControl} variant="raised" color="primary"
                        disabled={!selection}>{t("checkIn.send")}</Button>
            </div>
        </>
    )
}

export default GeoCheckIn