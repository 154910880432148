import {h} from "preact";
import {CookiesProvider} from "react-cookie";
import App from "./app";


const AppCookie = () => (
        <CookiesProvider>
            <App />
        </CookiesProvider>
    );

export default AppCookie;
