import {computed} from "@preact/signals";
import {h} from 'preact';
import {useContext} from "preact/hooks";

import {State, Storage} from "../../components/app-state";
import GeoCheckIn from "../../components/brevet-info/geo-check-in";
import {Brevet} from "../../models/brevet";

type CheckInRouteProps = {
    uid: string;
    distance: number;
}

const CheckInRoute = ({uid, distance}: CheckInRouteProps) => {
    const {brevets}: State = useContext(Storage);
    const brevet = computed(() => brevets.value?.find((b: Brevet) => b.uid === uid));


    return <GeoCheckIn brevet={brevet.value} distance={distance} />;
};

export default CheckInRoute;
