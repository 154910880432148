import {Barcode, EBarcodeSource, TBarcode, TBarcodeQueue, TSavedBarcode} from "../../models/barcode";
import {NONE_CHECKPOINT} from '../../models/checkpoint';
import {Offline} from "../../models/offline";
import {createBarcode} from "../firestore-api";
import {getValue, injectToken, removeToken, replaceToken} from "../settings";
import {TAuth} from "../../models/auth";

// 12 days for timestamp comparison - used to remove old barcodes from the queue
const DOZEN_DAYS_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 12;
const PREFIX = 'random_';

export function enqueueBarcode(
    source: EBarcodeSource,
    // rider uid or checkpoint uid
    sourceUid: string = NONE_CHECKPOINT,
    barcode: TBarcode,
    auth: TAuth,
    // token?: string,                //auth.value?.idToken,

    // userId?: string, // auth.value?.localId
): Promise<string> {
    const barcodeUid = PREFIX + Math.random().toString(36).substring(2, 11);

    injectToken('barcodes', barcodeUid, {
        source, sourceUid, barcode, token: auth.refreshToken,
    });

    if (navigator.onLine) {
        // send old codes from the queue
        repeatSending(barcodeUid);

        // the latest code
        return createBarcode(source, sourceUid, barcode, auth.refreshToken)
            .then((uid: string) => replaceToken('barcodes', barcodeUid, uid))
            .catch(error => {
                console.error('Barcode creation failure', error);
                return Promise.reject(error);
            });
    }
    return Promise.reject(new Offline('working offline'));
}

// try sending old codes again
export function repeatSending(skip?: string, force = false) {
    const savedCodes: TBarcodeQueue = getValue('barcodes') || {};
    const cutOffDate = new Date(Date.now() - DOZEN_DAYS_IN_MILLISECONDS);

    // check all the records
    for (const oldUid in savedCodes) {
        const savedCode: TSavedBarcode = savedCodes[oldUid];

        // confirmed transmission doesn't have a temporary UID prefix
        if (!oldUid.startsWith(PREFIX)) {
            // cleanup of old records out of interest
            if (savedCode.barcode.time < cutOffDate) {
                removeToken('barcodes', oldUid);
            }
            // may force sending the code again even if successfully delivered
            if (!force) {
                continue;
            }
        }
        // the most recent code is being sent immediately
        if (oldUid === skip) {
            continue;
        }

        // TODO: refresh the tokens
        // send the temporary code again
        createBarcode(savedCode.source, savedCode.sourceUid, savedCode.barcode, savedCode.token)
            .then(uid => replaceToken('barcodes', oldUid, uid));
    }
}

export function listQueue() {
    const savedCodes = getQueue()

    return Object.entries(savedCodes)
        .map(([uid, value]) => ({
            time: value.barcode.time as Date,
            code: value.barcode.code,
            sent: !uid.startsWith(PREFIX),
        }))
        .sort((a, b) => b.time.getTime() - a.time.getTime());
}

export function getQueue(): TBarcodeQueue {
    const savedCodes: TBarcodeQueue = getValue('barcodes') || {};

    Object.entries(savedCodes).forEach(([uid, value]) => savedCodes[uid].barcode = Barcode.fromDoc(value.barcode));
    return savedCodes;
}
