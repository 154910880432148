import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {useContext, useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {TAuthEmail} from "../../models/auth";
import {EmailFlow} from "../../models/email-flow";
import {signUpEmail, updateInfo} from "../../services/auth";
import {State, Storage} from "../app-state";

import style from './style.scss';

type CreateAccountDialogProps = {
    state: Signal<EmailFlow>;
}
type CreateAccountDialogState = {
    email: string;
    name: string;
    password: string;
    error: string;
}
const CreateAccountDialog = ({state}: CreateAccountDialogProps) => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage)

    const [credentials, setState] = useState<CreateAccountDialogState>({
        email: state.value.email,
        name: null,
        password: null,
        error: null
    });
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    const updateName = (event) => setState({...credentials, name: event.target.value});
    const updatePassword = (event) => setState({...credentials, password: event.target.value});
    const updateError = (state: string) => setState({...credentials, error: state});

    const submitAccount = () => {
        if (!credentials.email || !credentials.name || !credentials.password) {
            return;
        }
        updateError(null);
        signUpEmail(credentials.email, credentials.password)
            .then((info: TAuthEmail) => Promise.all([info, updateInfo(info.idToken, credentials.name)]))
            .then(([info, update]: [TAuthEmail, TAuthEmail]) => auth.value = {
                ...info,
                providerId: 'password',
                displayName: update.displayName
            })
            .catch(error => {
                console.error("error", error);
                // EMAIL_EXISTS or WEAK_PASSWORD : Password should be at least 6 characters
                updateError(error.message)
            })
    }

    return (
        <>
            <h2>{t("createAccount.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("createAccount.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error === 'EMAIL_EXISTS' ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("createAccount.exists")}</div>
            <Input type="text" name="name" required autocomplete="name" floatingLabel={true}
                   label={t("createAccount.name")} value={credentials.name} onChange={updateName} />
            <Input type="password" name="password" minlength="6" required autocomplete="new-password"
                   floatingLabel={true}
                   label={t("createAccount.password")} value={credentials.password} onChange={updatePassword} />
            <div className={`${credentials.error?.startsWith('WEAK_PASSWORD') ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("createAccount.weak")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("createAccount.cancel")}</Button>
                <Button onClick={submitAccount} variant="raised" color="primary">{t("createAccount.save")}</Button>
            </div>
        </>
    )
}

export default CreateAccountDialog;
