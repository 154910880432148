// FIXME: LatLng + nested fields
import {LngLat} from "../../models/track-point";

export function documentToJson(fields) {
    const result = {};
    for (const key in fields) {
        const value = fields[key]
        if ('mapValue' == key) {
            return documentToJson(value.fields || {});
        }
        if ('arrayValue' == key) {
            return value.values ? value.values.map(l => documentToJson(l)) : [];
        }
        if ('timestampValue' == key) {
            return new Date(value);
        }
        if ('integerValue' == key) {
            return parseInt(value, 10);
        }
        if ('doubleValue' == key) {
            return parseFloat(value);
        }
        if ('geoPointValue' == key) {
            return new LngLat(value.longitude, value.latitude);
        }
        const item = ['stringValue', 'booleanValue', 'nullValue'].find(t => t === key)
        if (item) {
            return value;
        }

        result[key] = documentToJson(value)
    }
    return result;
}
