import {signal} from "@preact/signals";
import Panel from "muicss/lib/react/panel";
import {h} from "preact";
import {route} from "preact-router";
import {useContext, useEffect} from "preact/hooks";
import {useCookies} from "react-cookie";

import {State, Storage} from "../../components/app-state";
import CreateAccountDialog from "../../components/login-email-flow/create";
import EmailLoginDialog from "../../components/login-email-flow/email";
import EmailPasswordDialog from "../../components/login-email-flow/password";
import PasswordRecoverDialog from "../../components/login-email-flow/recover";
import EmailSentDialog from "../../components/login-email-flow/sent";
import EmailUsedDialog from "../../components/login-email-flow/used";
import {EmailFlow, EMailLogin} from "../../models/email-flow";

import style from './style.scss';

const emailState = signal<EmailFlow>(new EmailFlow())

const EmailLoginRoute = () => {
    const [cookies] = useCookies(["state", "provider", "back_to"]);
    const {auth}: State = useContext(Storage)

    useEffect(() => {
        // reset the form on every visit
        emailState.value = new EmailFlow()
    }, [])
    useEffect(() => {
        // go back after authentication
        if (auth.value) {
            route(cookies.back_to || '/', true)
        }
    }, [auth.value])

    let inner = null;
    switch (emailState.value.status) {
        case EMailLogin.EnterEmail:
            inner = <EmailLoginDialog state={emailState} />
            break;
        case EMailLogin.EmailOk:
            inner = <EmailPasswordDialog state={emailState} />
            break;
        case EMailLogin.EmailUsed:
            inner = <EmailUsedDialog email={emailState.value.email} provider={emailState.value.provider} />
            break;
        case EMailLogin.RecoverPassword:
            inner = <PasswordRecoverDialog state={emailState} />
            break;
        case EMailLogin.EmailSent:
            inner = <EmailSentDialog email={emailState.value.email} />
            break;
        case EMailLogin.CreateAccount:
            inner = <CreateAccountDialog state={emailState} />
    }
    return <div className={style.loginContainer}>
        <Panel className={style.panel}>
            {inner}
        </Panel>
    </div>
}

export default EmailLoginRoute;
