import {Provider} from "./provider";

export enum EMailLogin {
    EnterEmail = "email",
    EmailUsed = "used",
    EmailOk = "password",
    WrongPassword = "wrong",
    CreateAccount = "create",
    RecoverPassword = "recover",
    EmailSent = "sent"
}

type TEmailFlowOptional = {
    email?: string;
    provider?: Provider;
}

type TEmailFlow = TEmailFlowOptional & {
    status: EMailLogin;
}

export class EmailFlow implements TEmailFlow {
    status = EMailLogin.EnterEmail;
    email = "";
    provider: Provider = null;

    constructor(status: EMailLogin = EMailLogin.EnterEmail, options: TEmailFlowOptional = {}) {
        this.status = status;
        // overwrite email and provider if given
        Object.assign(this, options);
    }
}