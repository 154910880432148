import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from 'muicss/lib/react/input';
import {Fragment, h} from 'preact';
import {useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {Provider} from "../../models/provider";
import {checkEmail} from "../../services/auth";

import style from './style.scss';

type EmailLoginDialogProps = {
    state: Signal<EmailFlow>;
}
type EmailLoginDialogState = {
    email: string;
    error: boolean;
}
const EmailLoginDialog = ({state}: EmailLoginDialogProps) => {
    const {t} = useTranslation();
    const [credentials, setState] = useState<EmailLoginDialogState>({email: state.value.email, error: false});

    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    const updateError = (state: boolean) => setState({...credentials, error: state});

    const submitEmail = () => {
        if (!credentials.email) {
            return;
        }
        updateError(false);
        // search for the account
        checkEmail(credentials.email)
            .then(info => {
                if (info.registered) {
                    if (info.signinMethods.includes('password')) {
                        // ask for a password
                        state.value = new EmailFlow(EMailLogin.EmailOk, {email: credentials.email})
                    } else {
                        // the account is taken by a provider
                        state.value = new EmailFlow(EMailLogin.EmailUsed, {
                            email: credentials.email,
                            provider: info.signinMethods[0] as Provider
                        })
                    }
                } else {
                    // not registered yet
                    state.value = new EmailFlow(EMailLogin.CreateAccount, {email: credentials.email})
                }
            })
            .catch(error => {
                console.error("error", error);
                if (error.message === 'INVALID_IDENTIFIER') {
                    updateError(true);
                }
            })
    }

    return (
        <>
            <h2>{t("emailLogin.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("emailLogin.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("emailLogin.incorrect")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("emailLogin.cancel")}</Button>
                <Button onClick={submitEmail} variant="raised" color="primary">{t("emailLogin.next")}</Button>
            </div>
        </>
    )
}

export default EmailLoginDialog;
