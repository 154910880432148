import Button from "muicss/lib/react/button";
import {h} from "preact"
import {route} from "preact-router";

const LinkButton = (props) => {
    const {to, children, ...rest} = props;

    return (
        <Button {...rest} onClick={() => route(to)}>
            {children}
        </Button>
    )
}

export default LinkButton;
