import Button from "muicss/lib/react/button";
import {Fragment, h} from 'preact';
import {useCookies} from "react-cookie";
import {Trans, useTranslation} from "react-i18next";

import {Provider, providerOptions} from "../../models/provider";
import {oauthRedirect} from "../../services/auth";

import style from './style.scss';

type EmailUsedDialogProps = {
    email: string;
    provider: Provider;
}

const EmailUsedDialog = ({email, provider}: EmailUsedDialogProps) => {
    const {t} = useTranslation();
    const setCookie = useCookies(["state", "provider"])[1];
    const providerName: string = providerOptions[provider].name;

    const signInWithProvider = () => {
        const state = (Math.random() * Date.now()).toString();
        setCookie('state', state, {path: '/'});
        setCookie('provider', provider, {path: '/'});
        oauthRedirect(provider, state)
    }

    return (
        <>
            <h2>{t("emailUsed.title")}</h2>
            <h3>{t("emailUsed.description")}</h3>
            <Trans i18nKey="emailUsed.suggestion">
                You've already used <strong>{{email}}</strong>. Sign in with {{provider: providerName}} to continue.
            </Trans>
            <div className={style.actions}>
                <Button variant="raised" color="primary"
                        onClick={signInWithProvider}>{t('login.signInWith', {provider: providerName})}</Button>
            </div>
        </>
    )
}

export default EmailUsedDialog;
