// TODO: reuse Mapbox API
export type TLngLat = {
    lng: number;
    lat: number;
};

export class LngLat implements TLngLat {
    lng: number;
    lat: number;

    constructor(lng: number, lat: number) {
        this.lng = lng;
        this.lat = lat;
    }
}

export type TrackPoint = {
    distance: number;
    coordinates: LngLat;
};
