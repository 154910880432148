import {effect, Signal, signal} from "@preact/signals";
import {createContext, h} from "preact";
import {useEffect} from "preact/hooks";

import {Brevet} from "../models/brevet";
import {loadProgress, ProgressStorage} from "../models/progress";
import {RiderPrivateDetails, RiderPublicDetails} from "../models/rider";
import {getValue, setValue} from "../services/settings";
import loadBrevets from "../utils/load-brevets";
import AppCookie from "./app-cookie";
import {TAuth, TRefreshTokens} from "../models/auth";

import {refreshTokens} from "../services/auth";

const REFRESH_10_MIN = 10 * 60 * 1000;

export type State = {
    brevets: Signal<Brevet[]>;
    expand: Signal<string[]>;
    loading: Signal<boolean>;
    error: Signal<string|null>;
    auth: Signal<TAuth>;
    riderPublic: Signal<RiderPublicDetails>;
    riderPrivate: Signal<RiderPrivateDetails>;
    progress: Signal<ProgressStorage>;
}


export const Storage = createContext<State>(null);

const createState = (): State => {
    const brevets: Signal<Brevet[]> = signal(loadBrevets(getValue('brevets') || []));
    const expand: Signal<string[]> = signal(getValue('expand') || []);
    const loading: Signal<boolean> = signal(false);
    const error: Signal<string> = signal(null);
    const auth: Signal<TAuth> = signal(getValue('auth'));
    const riderPublic: Signal<RiderPublicDetails> = signal(getValue('riderPublic'));
    const riderPrivate: Signal<RiderPrivateDetails> = signal(getValue('riderPrivate'));
    const progress: Signal<ProgressStorage> = signal(loadProgress(getValue('progress')) || {});
    // TODO: wipe out old progress

    return {brevets, expand, loading, error, auth, riderPublic, riderPrivate, progress};
}

const initialState = createState();
effect(() => {
    setValue('brevets', initialState.brevets.value)
});
effect(() => {
    setValue('expand', initialState.expand.value)
});
effect(() => {
    setValue('auth', initialState.auth.value)
});
effect(() => {
    setValue('riderPublic', initialState.riderPublic.value)
});
effect(() => {
    setValue('riderPrivate', initialState.riderPrivate.value)
});
effect(() => {
    setValue('progress', initialState.progress.value)
});

const AppState = () => {
    useEffect(() => {
        const interval = setInterval(() => {
            // skip polling if no internet or no tokens
            if (navigator.onLine && initialState.auth.value && 'refreshToken' in initialState.auth.value) {
                refreshTokens(initialState.auth.value.refreshToken)
                    .then((json: TRefreshTokens) => initialState.auth.value = {
                        ...initialState.auth.value,
                        idToken: json.id_token,
                        refreshToken: json.refresh_token,
                        oauthAccessToken: json.access_token,
                        localId: json.user_id,
                    })
                    .catch(error => console.error("Token refresh error", error))
            }
        }, REFRESH_10_MIN);
        return () => clearInterval(interval);
    }, []);

    return (
        <Storage.Provider value={initialState}>
            <AppCookie />
        </Storage.Provider>
    );
}

export default AppState;
