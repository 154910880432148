import {Checkpoint} from "../models/checkpoint";
import {LngLat} from "../models/track-point";

export function quickSearch(center: LngLat, checkpoints?: Checkpoint[]): Checkpoint[] {
    return !center ? [] : checkpoints?.filter((cp: Checkpoint) => Checkpoint.prototype
        .isOnline.call(cp, new Date()))
        .map((point: Checkpoint) => ({
            ...point,
            delta: geoDistance(point.coordinates?.lat || 0,
                point.coordinates?.lng || 0,
                center.lat, center.lng)
        } as Checkpoint))
        .filter((point: Checkpoint) => 1200 > (point.delta || Infinity));
}

/**
 * Generic geo distance calculation
 *
 * @param lat1 - Latitude of the first point
 * @param lon1 - Longitude of the first point
 * @param lat2 - Latitude of the second point
 * @param lon2 - Longitude of the second point
 */

export const geoDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    if (lat1 === lat2 && lon1 === lon2) {
        return 0;
    }
    const φ1 = lat1 * Math.PI / 180;
    const φ2 = lat2 * Math.PI / 180;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const R = 6371e3;
    return Math.acos(Math.sin(φ1) * Math.sin(φ2) + Math.cos(φ1) * Math.cos(φ2) * Math.cos(Δλ)) * R;
};
