import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import ru from "./ru.json";
import en from "./en.json";

export type Language = {
    code: string;
    name: string;
};
export const defaultLanguage = 'en';

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: false,
        fallbackLng: defaultLanguage,
        detection: {
            order: ['cookie', 'navigator'],
            lookupCookie: 'i18next',
            caches: ['cookie'],
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: { translation: en },
            ru: { translation: ru },
        }
    });

export default i18n;
