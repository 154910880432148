import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {sendRecover} from "../../services/auth";

import style from './style.scss';

type PasswordRecoverDialogProps = {
    state: Signal<EmailFlow>;
}
type PasswordRecoverDialogState = {
    email: string;
    error: string;
}
const PasswordRecoverDialog = ({state}: PasswordRecoverDialogProps) => {
    const {t} = useTranslation();

    const [credentials, setState] = useState<PasswordRecoverDialogState>({email: state.value.email, error: null});
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    const updateError = (state: string) => setState({...credentials, error: state});

    const submitEmail = () => {
        if (!credentials.email) {
            return;
        }
        updateError(null);
        sendRecover(credentials.email)
            .then(info => {
                state.value = new EmailFlow(EMailLogin.EmailSent, {email: info.email})
            })
            .catch(error => {
                console.error("error", error);
                // EMAIL_NOT_FOUND or INVALID_EMAIL
                updateError(error.message)
            })
    }
    return (
        <>
            <h2>{t("passwordRecover.title")}</h2>
            <p>{t("passwordRecover.suggestion")}</p>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("passwordRecover.email")} value={credentials.email} onChange={updateEmail} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("passwordRecover.noMatch")}</div>
            <div className={style.actions}>
                <Button onClick={() => history.back()}>{t("passwordRecover.cancel")}</Button>
                <Button onClick={submitEmail} variant="raised" color="primary">{t("passwordRecover.send")}</Button>
            </div>
        </>
    )
}

export default PasswordRecoverDialog;
