export const SETTINGS_PREFIX = 'brevet.top/';

export function getValue(key: string) {
  const value = localStorage.getItem(SETTINGS_PREFIX + key);
  return value === 'undefined' ? undefined : JSON.parse(value || 'null');
}

export function setValue(key: string, value: unknown): void {
  localStorage.setItem(SETTINGS_PREFIX + key, JSON.stringify(value));
}

export function removeKey(key: string) {
  return localStorage.removeItem(SETTINGS_PREFIX + key);
}

export function injectToken(key: string, token: string, value: unknown): boolean {
  const storage = getValue(key);

  if (!storage) {
    setValue(key, {[token]: value});
    return true;
  } else if (storage instanceof Object) {
    storage[token] = value;
    setValue(key, storage);
    return true;
  }
  console.error(`Unsupported type of ${key}`);
  return false;
}

export function replaceToken(key: string, oldToken: string, newToken: string): string {
  const storage = getValue(key);

  if (!storage) {
    console.error(`Key not found: ${key}`);
  } else if (storage instanceof Object) {
    if (oldToken in storage) {
      storage[newToken] = storage[oldToken];
      delete storage[oldToken];
      setValue(key, storage);
    } else {
      console.error(`Token not found in ${key}: ${oldToken}`);
    }
  } else {
    console.error(`Unsupported type of ${key}`);
  }
  return newToken;
}

export function removeToken(key: string, token: string) {
  const storage = getValue(key);

  if (!storage) {
    console.error(`Key not found: ${key}`);
  } else if (storage instanceof Object) {
    if (token in storage) {
      delete storage[token];
      setValue(key, storage);
    } else {
      console.error(`Token not found in ${key}: ${token}`);
    }
  } else {
    console.error(`Unsupported type of ${key}`);
  }
}
