import Button from "muicss/lib/react/button";
import {Fragment, h} from 'preact';
import {Trans, useTranslation} from "react-i18next";

import style from './style.scss';

type EmailSentDialogProps = {
    email: string;
}

const EmailSentDialog = ({email}: EmailSentDialogProps) => {
    const {t} = useTranslation();

    return (
        <>
            <h2>{t("emailSent.title")}</h2>
            <p><Trans i18nKey="emailSent.description">
                Follow the instructions sent to <strong>{{email}}</strong> to recover your password.
            </Trans></p>
            <div className={style.actions}>
                <Button onClick={() => history.back()} variant="raised" color="primary">{t("emailSent.done")}</Button>
            </div>
        </>
    )
}

export default EmailSentDialog;
