import "core-js/es/array/find";
import "core-js/es/array/find-index";
import "core-js/es/array/includes";
import "core-js/es/object/assign";
import "core-js/es/object/entries";
import "core-js/es/string/starts-with";

import App from './components/app-i18n';

import './style/index.scss';

export default App;
