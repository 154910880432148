import {Signal} from "@preact/signals";
import Button from "muicss/lib/react/button";
import Input from "muicss/lib/react/input";
import {Fragment, h} from 'preact';
import {Link} from "preact-router";
import {useContext, useState} from "preact/hooks";
import {useTranslation} from "react-i18next";

import {TAuthEmail} from "../../models/auth";
import {EmailFlow, EMailLogin} from "../../models/email-flow";
import {signInEmail} from "../../services/auth";
import {State, Storage} from "../app-state";

import style from './style.scss';

type EmailPasswordDialogProps = {
    state: Signal<EmailFlow>;
}

type EmailPasswordDialogState = {
    email: string;
    password: string;
    error: boolean;
}

const EmailPasswordDialog = ({state}: EmailPasswordDialogProps) => {
    const {t} = useTranslation();
    const {auth}: State = useContext(Storage)

    const [credentials, setState] = useState<EmailPasswordDialogState>({
        email: state.value.email, password: null, error: false
    });
    const updateEmail = (event) => setState({...credentials, email: event.target.value});
    const updatePassword = (event) => setState({...credentials, password: event.target.value});
    const updateError = (state: boolean) => setState({...credentials, error: state});

    const submitEmailPassword = () => {
        if (!credentials.email || !credentials.password) {
            return;
        }
        updateError(false);
        signInEmail(credentials.email, credentials.password)
            .then((info: TAuthEmail) => auth.value = {...info, providerId: 'password'})
            .catch(error => {
                console.error("error", error);
                if (error.message === 'INVALID_PASSWORD') {
                    updateError(true);
                }
            })
    }
    const recoverPassword = (event) => {
        event.preventDefault();
        state.value = new EmailFlow(EMailLogin.RecoverPassword, {email: credentials.email})
    }

    return (
        <>
            <h2>{t("emailPassword.title")}</h2>
            <Input type="email" name="email" required autocomplete="email" floatingLabel={true}
                   label={t("emailPassword.email")} value={credentials.email} onChange={updateEmail} />
            <Input type="password" name="password" minlength="6" required autocomplete="current-password"
                   floatingLabel={true}
                   label={t("emailPassword.password")} value={credentials.password} onChange={updatePassword} />
            <div className={`${credentials.error ? '' : style.hidden}
                ${style.error} mui--text-accent-secondary mui--text-caption`}>{t("emailPassword.noMatch")}</div>

            <div className={style.table}>
                <div className={style.row}>
                    <div className={`${style.cell} mui--text-caption`}>
                        <Link href="#" onClick={recoverPassword}>{t("emailPassword.trouble")}</Link>
                    </div>
                    <div className={`${style.actions} ${style.cell}`}>
                        <Button onClick={submitEmailPassword} variant="raised"
                                color="primary">{t("emailPassword.title")}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailPasswordDialog;
