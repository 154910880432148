type Mapping = {
    [key: string]: unknown;
}

export function expandResponse(response: Response) {
    return Promise.all([response, response.json()])
        .then(([response, json]) => {
            if (response.ok) {
                return json
            }
            throw new Error(json.error.message)
        })
}

export function expandFields(response: Response): Promise<Mapping> {
    return expandResponse(response)
        .then(json => json.fields)
}

export function expandDocuments(response: Response): Promise<Mapping[]> {
    return expandResponse(response)
        .then(json => json.documents)
}