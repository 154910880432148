export enum EProgress {
    Watch = "watch",
    Logged = "logged",
    Registered = "registered",
    Control = "control",
    Finish = "finish",
    DNF = "dnf"
}

interface IProgress {
    status: EProgress;
    distance?: number;
    checkpointUid?: string;

    change(EProgress, distance?: number, checkpointUid?: string): Progress;
}

type EnumDictionary<T extends string | symbol | number, U> = {
    [K in T]: U;
};

const progressTree: EnumDictionary<EProgress, EProgress[]> = {
    [EProgress.Watch]: [EProgress.Logged],
    [EProgress.Logged]: [EProgress.Registered, EProgress.Control],
    [EProgress.Registered]: [EProgress.Control],
    [EProgress.Control]: [EProgress.Control, EProgress.Finish, EProgress.DNF],
    [EProgress.Finish]: [],
    [EProgress.DNF]: []
}

export type ProgressStorage = {
    [uid: string]: IProgress
}

export class Progress implements IProgress {
    status: EProgress;
    distance?: number;
    checkpointUid?: string;

    constructor(status: EProgress = EProgress.Watch, distance?: number, checkpointUid?: string) {
        this.status = status;
        this.distance = distance;
        this.checkpointUid = checkpointUid;
    }

    static fromDoc(doc?: IProgress) {
        if (!doc) {
            return null;
        }
        return new Progress(doc.status, doc.distance)
    }

    change(status: EProgress, distance?: number, checkpointUid?: string): Progress {
        const next = progressTree[this.status]
        if (!next.find(s => status === s)) {
            throw new Error(`Not allowed status ${status}`)
        }
        return new Progress(status, distance, checkpointUid);
    }
}

export function loadProgress(storage: ProgressStorage): ProgressStorage {
    for (const uid in storage) {
        storage[uid] = Progress.fromDoc(storage[uid])
    }
    return storage;
}