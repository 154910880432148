import {LngLat} from "../../models/track-point";
export function jsonToDocument(value) {
    if (value === null) {
        return {nullValue: null};
    } else if (value === 'true' || value === 'false' || typeof value == 'boolean') {
        return {booleanValue: value};
    } else if (value instanceof Date) {
        return {timestampValue: value.toISOString()};
    } else if (value instanceof LngLat) {
        return {geoPointValue: {longitude: value.lng, latitude: value.lat}};
    } else if (typeof value == 'string') {
        return {stringValue: value};
    } else if (!isNaN(value)) {
        if (value.toString().indexOf('.') != -1)
            return {doubleValue: value};
        return {integerValue: value};
    } else if (value && value.constructor === Array) {
        return {arrayValue: {values: value.map(v => jsonToDocument(v))}};
    } else if (typeof value === 'object') {
        const obj = {};
        for (const key in value) {
            obj[key] = jsonToDocument(value[key]);
        }
        return {mapValue: {fields: obj}};
    }
}

