import {EBarcodeSource, TBarcode} from "../../models/barcode";
import {TBrevetFull, TBrevetShort} from "../../models/brevet";
import {NONE_CHECKPOINT, TCheckpoint} from "../../models/checkpoint";
import {RiderPrivateDetails, RiderPublicDetails} from "../../models/rider";
import {TResults, TRiderCheckIn} from "../../models/rider-checkin";
import {TRefreshTokens} from "../../models/auth";
import {refreshTokens} from "../auth";
import {expandDocuments, expandFields, expandResponse} from "../../utils/fetch-response";

import {documentToJson} from "./document-to-json";
import {jsonToDocument} from "./json-to-document";

import environment from "../../environment";

const BASE_URL = `${environment.FIRESTORE_URL}/v1/projects/${environment.PROJECT_ID}/databases/(default)/documents`


export function getRider(uid: string): Promise<RiderPublicDetails> {
    return fetch(`${BASE_URL}/riders/${uid}`)
        .then(expandFields)
        .then(documentToJson);
}

export function getPrivateRider(uid: string, token: string): Promise<RiderPrivateDetails> {
    return fetch(`${BASE_URL}/private/${uid}`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then(expandFields)
        .then(documentToJson);
}

export function getBrevetList(): Promise<TBrevetShort[]> {
    return fetch(`${BASE_URL}/brevets/list`)
        .then(expandFields)
        .then(documentToJson)
        .then(data => data.brevets)
}

export function getBrevetInfo(uid: string): Promise<TBrevetFull> {
    return fetch(`${BASE_URL}/brevets/${uid}`)
        .then(expandFields)
        .then(documentToJson)
}

export function getBrevetCheckpoints(uid: string): Promise<TCheckpoint[]> {
    return fetch(`${BASE_URL}/brevets/${uid}/checkpoints?orderBy=distance`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
}

export function getCheckpointCheckins(brevetUid: string, checkpointUid: string): Promise<TRiderCheckIn[]> {

    return fetch(`${BASE_URL}/brevets/${brevetUid}/checkpoints/${checkpointUid}/riders`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
        .then(riders => riders.map(({uid, ...rest}) => ({...rest, riderUid: uid, checkpointUid})))
}

export function getBrevetCheckins(uid: string): Promise<TResults> {
    const checkpointIndices = {};
    // TODO: cache checkpoints, they won't change
    return getBrevetCheckpoints(uid)
        .then((checkpoints: TCheckpoint[]) => {
            checkpoints.map((cp, index) => checkpointIndices[cp.uid] = index);
            return checkpoints;
        })
        .then((checkpoints: TCheckpoint[]) => Promise.all(checkpoints.map(cp => getCheckpointCheckins(uid, cp.uid))))
        .then((riders: TRiderCheckIn[][]) => riders.reduce(
            (dict, riders) => {
                riders.forEach(rider => {
                    if (!dict[rider.riderUid]) {
                        dict[rider.riderUid] = rider;
                    }
                    const time = dict[rider.riderUid].time;
                    time[checkpointIndices[rider.checkpointUid]] = rider.time[0]
                    dict[rider.riderUid] = {
                        ...rider,
                        time,
                    }
                })
                return dict;
            }, {} as TResults),
        )
}

export function getCheckpointList(): Promise<TCheckpoint[]> {
    return fetch(`${BASE_URL}/checkpoints`)
        .then(expandDocuments)
        .then(data => data.map(cp => documentToJson(cp.fields)))
}

export function getCheckpointInfo(uid: string): Promise<TCheckpoint> {
    return fetch(`${BASE_URL}/checkpoints/${uid}`)
        .then(expandFields)
        .then(documentToJson)
}

export function createBarcode(
    root: EBarcodeSource,
    controlUid: string = NONE_CHECKPOINT,
    barcode: TBarcode,
    token: string,
): Promise<string> {
    if (!token) {
        return Promise.reject('No authentication token');
    }

    return refreshTokens(token)
        .then((json: TRefreshTokens) => [json.id_token, json.user_id])
        .catch(error => {
            console.error("Token refresh error", error)
            throw error;
        })
        .then(([idToken, localId]) => [
            idToken,
            JSON.stringify(
                jsonToDocument({
                ...barcode,
                control: controlUid,
                owner: localId
            }).mapValue)
        ])
        .then(([idToken, payload]) => fetch(`${BASE_URL}/${root}/${controlUid}/barcodes`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
            },
            body: payload
        }))
        .then(expandResponse)
        .then(response => {
            const uid = response.name.split('barcodes/')[1];
            return {...response.fields, uid};
        })
        .then(data => data.uid)
        /*
        TODO: enable ?
        .then(docRef => {
            barcode.uid = docRef.id;
            return updateDoc(docRef, {uid: docRef.id})
                .then(() => docRef.id);
        })
        */
        .catch(error => {
            console.error('Error adding document: ', error);
            return Promise.reject(error);
        });
}
