import sha256 from '@cryptography/sha256';
import querystring from "querystring";

import environment from "../../environment";
import {TAuth, TAuthEmail, TEmailInfo, Tokens, TRefreshTokens} from "../../models/auth";
import {ProviderOptions, providerOptions} from "../../models/provider";
import {expandResponse} from "../../utils/fetch-response";

const IDENTITY_TOOLKIT_URL = 'https://identitytoolkit.googleapis.com/v1/accounts';
const SECURE_TOKEN_URL = `https://securetoken.googleapis.com/v1/token?key=${environment.GOOGLE_APP_ID}`;

const identityEndpoint = (command: string): string => `${IDENTITY_TOOLKIT_URL}:${command}?key=${environment.GOOGLE_APP_ID}`
// eslint-disable-next-line prefer-template
const LOGIN_URL = window.location.href.split('/').slice(0,3).join('/') + '/login';

export function oauthRedirect(providerId: string, state: string) {
    const {baseUrl, ...customOptions} = providerOptions[providerId];

    const defaultOptions: ProviderOptions = {
        redirect_uri: LOGIN_URL,
        nonce: sha256(state, 'hex'),
        state,
    }

    Object.assign(defaultOptions, customOptions)
    const params = querystring.stringify(defaultOptions);
    window.location.href = `${baseUrl}?${params}`;
}

export const urlTokens = (): Tokens => querystring.parse(window.location.href.split("#")[1]) as unknown as Tokens;

function identityPayload(tokens: Tokens, providerId: string) {
    const {access_token, id_token, state} = tokens;
    const payload = {
        access_token,
        id_token,
        providerId,
        nonce: state
    }

    for (const name of ["access_token", "id_token"]) {
        if (!payload[name]) {
            delete payload[name]
        }
    }

    return JSON.stringify({
        postBody: querystring.stringify(payload),
        requestUri: LOGIN_URL,
        returnIdpCredential: true,
        returnSecureToken: true,
        returnRefreshToken: true,
    })
}

export function queryIdentityApi(tokens: Tokens, providerId: string): Promise<TAuth> {
    const payload = identityPayload(tokens, providerId);
    return fetch(identityEndpoint('signInWithIdp'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    })
        .then(expandResponse)
}

function tokenPayload(refresh_token: string) {
    return querystring.stringify({
        refresh_token,
        grant_type: 'refresh_token'
    })
}

export function refreshTokens(token: string): Promise<TRefreshTokens> {
    const payload = tokenPayload(token)
    return fetch(SECURE_TOKEN_URL, {
        method: 'POST',
        headers: {"Content-Type": "application/x-www-form-urlencoded"},
        body: payload
    }).then(expandResponse)
}

export function checkEmail(email: string): Promise<TEmailInfo> {
    const payload = JSON.stringify({
        identifier: email,
        continueUri: LOGIN_URL,
    });
    return fetch(identityEndpoint('createAuthUri'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

export function signInEmail(email: string, password: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,
        email,
        password
    });
    return fetch(identityEndpoint('signInWithPassword'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

export function signUpEmail(email: string, password: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,
        email,
        password
    });
    return fetch(identityEndpoint('signUp'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

export function updateInfo(idToken: string, displayName: string): Promise<TAuthEmail> {
    const payload = JSON.stringify({
        returnSecureToken: true,
        idToken,
        displayName
    });
    return fetch(identityEndpoint('update'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}

export function sendRecover(email: string): Promise<TEmailInfo> {
    const payload = JSON.stringify({
        requestType: "PASSWORD_RESET",
        email
    });
    return fetch(identityEndpoint('sendOobCode'), {
        method: 'POST',
        headers: {"Content-Type": "application/json"},
        body: payload
    }).then(expandResponse)
}